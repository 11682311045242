import { Blog } from '@/src/payload-types';
import React from 'react';
import styles from '../InsightDetails.module.scss';
import MoreInsightsItem from './MoreInsightsItem';

interface IProps {
  moreBlogs: Blog[];
}

const MoreInsights: React.FC<IProps> = ({ moreBlogs }) => {
  if (moreBlogs.length === 0) {
    return null;
  }

  return (
    <div className={styles.moreContainer}>
      <div className={styles.moreInnerContainer}>
        <h2 className={`${styles.moreH2} h2`}>More</h2>
        <div className={styles.moreFlexContainer}>
          {moreBlogs?.map((blog) => <MoreInsightsItem blog={blog} key={blog?.id} />)}
        </div>
      </div>
    </div>
  );
};

export default MoreInsights;
