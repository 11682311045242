import { Blog } from '@/src/payload-types';
import React from 'react';
import styles from './InsightDetails.module.scss';
import Image from 'next/image';
import MoreInsights from './more-insights/MoreInsights';
import ReadingTimeEstimate from './ReadingTimeEstimate';
import imageLoader from '@/src/imageLoader';
import { serializeLexical } from '@/src/payload/lexical/serializeLexical';

interface IProps {
  blogData: Blog;
  moreBlogs: Blog[];
}

const InsightDetailsPage: React.FC<IProps> = ({ blogData, moreBlogs }) => {
  const blogCategory = blogData?.blogCategory;
  const author = blogData?.author && typeof blogData?.author !== 'string' ? blogData?.author : null;
  const authorImage = author && typeof author?.profileImage !== 'string' ? author?.profileImage : null;
  const avatarImage = author && typeof author?.avatarImage !== 'string' ? author?.avatarImage : null;

  const AUTHOR_IMAGE_SIZE = '80px';
  const AUTHOR_FOOTER_IMAGE_SIZE = '150px';
  const COVER_IMAGE_SIZE = '100vw';

  const coverImage =
    blogData?.coverImage && typeof blogData?.coverImage !== 'string' ? blogData?.coverImage : undefined;

  const serializedLexicalData = blogData?.richTextContent
    ? serializeLexical(blogData?.richTextContent, { headingClass: 'insights' })
    : null;

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          {blogCategory && typeof blogCategory !== 'string' ? (
            <h1 className={styles.blogTagText}>{blogCategory?.name}</h1>
          ) : null}
          <h2 className={styles.blogTitleText}>{blogData?.header?.title}</h2>
        </div>
        {blogData?.header?.subtitle && (
          <p className={styles.blogSubTitleText}>{blogData?.header?.subtitle}</p>
        )}
        <div className={styles.authorAndTime}>
          <div className={styles.authorContainer}>
            {avatarImage?.filename && (
              <Image
                className={styles.avatarImage}
                height={avatarImage?.height || 0}
                width={avatarImage?.width || 0}
                loader={imageLoader}
                sizes={AUTHOR_IMAGE_SIZE}
                src={avatarImage.filename}
                alt={avatarImage.alt}
              />
            )}
            <div>
              <h5 className={styles.authorName}>{author?.name}</h5>
              <h5 className={styles.authorTitle}>{author?.title}</h5>
            </div>
          </div>
          <ReadingTimeEstimate
            updatedAt={blogData?.updatedAt}
            readingTime={blogData?.readingTime}
            theme="light"
            createdAt={blogData?.createdAt}
          />
        </div>
      </div>
      {coverImage?.filename && (
        <div className={styles.coverImageContainer}>
          <Image
            className={styles.coverImage}
            objectFit="cover"
            height={coverImage?.height || 0}
            width={coverImage?.width || 0}
            sizes={COVER_IMAGE_SIZE}
            src={coverImage.filename}
            loader={imageLoader}
            alt={coverImage.alt}
          />
        </div>
      )}
      {serializedLexicalData && (
        <div className={`${styles.blogContent} bodyLarge`}>{serializedLexicalData}</div>
      )}
      <div className={styles.authorContainerFooter}>
        {authorImage?.filename && (
          <Image
            className={styles.authorFooterImage}
            height={authorImage?.height || 0}
            width={authorImage?.width || 0}
            sizes={AUTHOR_FOOTER_IMAGE_SIZE}
            src={authorImage.filename}
            loader={imageLoader}
            alt={authorImage.alt}
          />
        )}
        <div className={styles.authorNameContainer}>
          <h5 className={styles.authorNameFooter}>{author?.name}</h5>
          <p className={styles.authorTitleFooter}>{author?.title}</p>
        </div>
        <p className={styles.authorBoiFooter}>{author?.bio}</p>
      </div>
      <MoreInsights moreBlogs={moreBlogs} />
    </div>
  );
};

export default InsightDetailsPage;
