'use client';
import { useLivePreview } from '@payloadcms/live-preview-react';
import { Blog, BlogCategory, Page } from '@/src/payload-types';
import React from 'react';
import PageWrapper from './PageWrapper';

interface IProps {
  page: Page | Blog | BlogCategory;
  moreBlogs?: Blog[];
  pageType: 'page' | 'blog' | 'blogCategory';
}

// Renders pages as a client component to serve live previews
const PagePreview: React.FC<IProps> = ({ page, moreBlogs, pageType }) => {
  const { isLoading, data } = useLivePreview({
    initialData: page,
    serverURL: process.env.NEXT_PUBLIC_API_URL || '',
  });

  return data && !isLoading && <PageWrapper page={data} moreBlogs={moreBlogs} pageType={pageType} />;
};

export default PagePreview;
