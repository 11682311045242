'use client';
import React from 'react';
import styles from '../Insights.module.scss';
import { BlogCategory } from '@/src/payload-types';

interface IProps {
  blogCategories: Partial<BlogCategory>[];
  setActiveCategory: React.Dispatch<React.SetStateAction<Partial<BlogCategory>>>;
  activeCategory: Partial<BlogCategory>;
}

const BlogCategoriesMenu: React.FC<IProps> = ({ blogCategories, setActiveCategory, activeCategory }) => {
  const handleSwitch = (blogCategory: Partial<BlogCategory>) => {
    setActiveCategory(blogCategory);
  };

  return (
    <div className={styles.menuContainer}>
      {blogCategories?.map((bc) => (
        <button
          key={bc?.id}
          className={styles.tagChip + ' ' + (bc?.id === activeCategory?.id && styles.activeTagChip)}
          onClick={() => handleSwitch(bc)}
          style={{
            backgroundColor: bc?.id === activeCategory?.id ? '#9747FF' : '',
            borderColor: bc?.id === activeCategory?.id ? '#9747FF' : '',
          }}
        >
          {bc?.name}
        </button>
      ))}
    </div>
  );
};

export default BlogCategoriesMenu;
