import BlockManager from '@/src/components/shared/BlockManager';
import { Blog, BlogCategory, Page } from '@/src/payload-types';

import React, { Fragment } from 'react';
import InsightDetailsPage from '../insights/details/InsightDetailsPage';
import BlogCategoryPage from '../insights/BlogCategoryPage';

interface IProps {
  pageType: 'page' | 'blog' | 'blogCategory';
  page: Page | Blog | BlogCategory;
  moreBlogs?: Blog[];
}

const PageWrapper: React.FC<IProps> = ({ page, moreBlogs, pageType }) => {
  let pageData;
  let blogData;
  let blogCategoryData;

  switch (pageType) {
    case 'page':
      pageData = page as Page;
      return pageData?.layout && BlockManager(pageData.layout);
    case 'blog':
      blogData = page as Blog;
      return blogData && moreBlogs && <InsightDetailsPage blogData={blogData} moreBlogs={moreBlogs} />;
    case 'blogCategory':
      blogCategoryData = page as BlogCategory;
      return <BlogCategoryPage {...blogCategoryData} />;
    default:
      return <Fragment />;
  }
};

export default PageWrapper;
