import { Blog } from '@/src/payload-types';
import React from 'react';
import BlogGalleryItem from './BlogGalleryItem';
import styles from '../Insights.module.scss';
interface IProps {
  blogs: Blog[];
}
const BlogGallery: React.FC<IProps> = ({ blogs }) => {
  return (
    <div className={styles.galleryContainer}>
      {blogs?.map((blog) => <BlogGalleryItem blog={blog} key={blog?.id} />)}
    </div>
  );
};

export default BlogGallery;
