import Masthead from './blocks/masthead/Masthead';
import { Page } from '@/src/payload-types';
import TwoColumn from './blocks/two-column/TwoColumn';
import ListItemsBanner from './blocks/list-items-banner/ListItemsBanner';
import GridCardSection from './blocks/grid-card-section/GridCardSection';
import SingleColumn from './blocks/single-column/SingleColumn';
import InsightHomePage from '../insights/InsightHomePage';
import SimpleMasthead from './blocks/simple-masthead/SimpleMasthead';
import Banner from './blocks/banner/Banner';

type blocks = Page['layout'][0];

const BlockManager = (blocks: blocks[]) => {
  return blocks.map((blockData, index) => {
    switch (blockData.blockType) {
      case 'masthead':
        return <Masthead {...blockData} key={index} />;

      case 'simpleMasthead':
        return <SimpleMasthead {...blockData} key={index} />;

      case 'twoColumn':
        return <TwoColumn {...blockData} key={index} />;

      case 'listItemsBanner':
        return (
          <ListItemsBanner
            {...blockData}
            key={index}
            style={index === blocks.length - 1 ? { marginBottom: '0' } : undefined}
          />
        );

      case 'gridCardSection':
        return <GridCardSection {...blockData} key={index} />;

      case 'singleColumn':
        return <SingleColumn {...blockData} key={index} />;

      case 'insightsHomepage':
        return <InsightHomePage {...blockData} key={index} />;

      case 'BannerRelation':
        return (
          <Banner
            {...blockData}
            key={index}
            style={index === blocks.length - 1 ? { marginBottom: '0' } : undefined}
          />
        );
    }
  });
};

BlockManager.defaultProps = {
  blocks: [],
};

export default BlockManager;
