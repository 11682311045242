'use client';
import React from 'react';
import styles from './InsightDetails.module.scss';
import { Blog } from '@/src/payload-types';

interface IProps {
  updatedAt: Blog['updatedAt'];
  createdAt: Blog['createdAt'];
  readingTime: Blog['readingTime'];
  theme: 'light' | 'dark';
}
const ReadingTimeEstimate: React.FC<IProps> = ({ updatedAt, readingTime, theme, createdAt }) => {
  function getTimeDifference(timestamp: string) {
    const currentTime = new Date();
    const pastTime = new Date(timestamp);
    const timeDifference = currentTime.getTime() - pastTime.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 52);

    if (weeks > 0) {
      return new Date(updatedAt).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    } else if (days > 0) {
      if (days > 7) {
        return pastTime.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      }
      return days === 1 ? '1 day ago' : days + ' days ago';
    } else if (hours > 0) {
      return hours === 1 ? '1 hour ago' : hours + ' hours ago';
    } else if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : minutes + ' minutes ago';
    } else {
      return seconds === 1 ? '1 second ago' : seconds + ' seconds ago';
    }
  }

  const minutesUnit = readingTime && readingTime > 1 ? 'Mins' : 'Min';
  return (
    <p
      className={styles.timeEstimate}
      style={{ color: theme === 'light' ? 'var(--neutral-900)' : 'var(--white-primary)' }}
      title={`Published on ${new Date(createdAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })}`}
    >
      {readingTime} {minutesUnit} Read | {getTimeDifference(updatedAt)}
    </p>
  );
};

export default ReadingTimeEstimate;
