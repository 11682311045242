'use client';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './Insights.module.scss';
import BlogGallery from '@/src/components/insights/blogs-gallery/BlogGallery';
import { BLOG_CONSTANTS } from '@/src/constants/payload-constants';
import { Blog, BlogCategory, IInsightsHomepage } from '@/src/payload-types';
import { useInsightsData } from '@/src/contexts/insights/InsightsContext';
import BlogCategoriesMenu from './blogs-gallery/BlogCategoriesMenu';
import FeaturedInsights from './FeaturedInsights';

const ALL_BLOGS_TAG = {
  name: BLOG_CONSTANTS.allBlogsTag,
  slug: '',
  id: '',
  updatedAt: '',
  createdAt: '',
};

const InsightHomePage: React.FC<IInsightsHomepage> = ({ featuredInsights }) => {
  const [activeCategory, setActiveCategory] = useState<Partial<BlogCategory>>(ALL_BLOGS_TAG);

  const { insightsData, refresh } = useInsightsData();

  useEffect(() => {
    !insightsData && refresh();
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsData]);

  let categories: Partial<BlogCategory>[] = insightsData?.blogCategories
    ? [ALL_BLOGS_TAG, ...insightsData?.blogCategories]
    : [ALL_BLOGS_TAG];

  const filteredBlogs = useMemo(() => {
    if (activeCategory.name === BLOG_CONSTANTS.allBlogsTag) {
      return insightsData?.blogs;
    }
    return insightsData?.blogs.filter(
      (blog) => typeof blog.blogCategory !== 'string' && blog.blogCategory.id === activeCategory.id,
    );
  }, [insightsData?.blogs, activeCategory]);

  let fi;
  if (featuredInsights && featuredInsights?.length > 0 && typeof featuredInsights[0] !== 'string') {
    fi = featuredInsights as Blog[];
  }
  return (
    <div>
      {fi && <FeaturedInsights featuredInsights={fi} />}
      <div className={styles.insightHomepageContainer}>
        <div className={styles.leftTopContainer}>
          <h3 className={'h3'}>Our Insights</h3>
          <p>Explore our insights on technology, design and strategy.</p>
          <BlogCategoriesMenu
            blogCategories={categories}
            setActiveCategory={setActiveCategory}
            activeCategory={activeCategory}
          />
        </div>
        <div className={styles.rightBottomContainer}>
          {filteredBlogs && <BlogGallery blogs={filteredBlogs} />}
        </div>
      </div>
    </div>
  );
};

export default InsightHomePage;
