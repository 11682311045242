'use client';

import React from 'react';
import { ITwoColumn } from '@/src/payload-types';
import styles from './TwoColumn.module.scss';
import { TWO_COLUMN_CONSTANTS } from '@/src/constants/payload-constants';
import { percentToDecimal } from '@/src/payload/utils/client.utils';
import ImageBlock from '../sub-components/ImageBlock';
import { serializeLexical } from '@/src/payload/lexical/serializeLexical';
import { LargeImageQuery } from '@/src/styles/responsive-breakpoints';

const TwoColumn: React.FC<ITwoColumn> = ({
  id,
  columns,
  widthRatio,
  positionOfLeftColOnMobile,
  ...props
}) => {
  let leftWidth, rightWidth, flexDirection, imgWidthOnMobile;
  const [columnHeights, setColumnHeights] = React.useState({
    leftColumn: 0,
    rightColumn: 0,
  });

  React.useEffect(() => {
    const twoColumnLeft = document.querySelector(`.two-column-left-${id}`);
    const twoColumnRight = document.querySelector(`.two-column-right-${id}`);
    setColumnHeights({
      leftColumn: twoColumnLeft?.clientHeight || 0,
      rightColumn: twoColumnRight?.clientHeight || 0,
    });
  }, [id]);

  switch (widthRatio) {
    case '30/70':
      leftWidth = '30%';
      rightWidth = '70%';
      break;
    case '50/50':
      leftWidth = '50%';
      rightWidth = '50%';
      break;
    case '70/30':
      leftWidth = '70%';
      rightWidth = '30%';
      break;
    default:
      leftWidth = '50%';
      rightWidth = '50%';
  }

  switch (positionOfLeftColOnMobile) {
    case 'top':
      flexDirection = 'column';
      break;
    case 'bottom':
      flexDirection = 'column-reverse';
      break;
    default:
      flexDirection = 'column';
      break;
  }

  const [leftColumn, rightColumn] = columns;

  if (leftColumn.blockType === 'twoColumnImageBlock') {
    if (leftColumn.mobileDimensions === TWO_COLUMN_CONSTANTS.mobileImgFull) {
      imgWidthOnMobile = '100%';
    } else if (leftColumn.mobileDimensions === TWO_COLUMN_CONSTANTS.mobileImgSameAsXl) {
      imgWidthOnMobile = `${percentToDecimal(leftWidth) * 1200}px`;
    }
  }
  if (rightColumn.blockType === 'twoColumnImageBlock') {
    if (rightColumn.mobileDimensions === TWO_COLUMN_CONSTANTS.mobileImgFull) {
      imgWidthOnMobile = '100%';
    } else if (rightColumn.mobileDimensions === TWO_COLUMN_CONSTANTS.mobileImgSameAsXl) {
      imgWidthOnMobile = `${percentToDecimal(rightWidth) * 1200}px`;
    }
  }

  const isCentered = React.useMemo(() => {
    if (rightColumn?.blockType === 'twoColumnImageBlock') {
      return columnHeights.leftColumn < columnHeights.rightColumn;
    }

    if (leftColumn?.blockType === 'twoColumnImageBlock') {
      return columnHeights.rightColumn < columnHeights.leftColumn;
    }

    return false;
  }, [columnHeights, leftColumn, rightColumn]);

  return (
    <div
      className={`${styles.container} bodyRegular`}
      style={
        {
          '--flex-direction': flexDirection,
          alignItems: isCentered ? 'center' : 'flex-start',
        } as React.CSSProperties
      }
    >
      <div
        className={`two-column-left-${id} ` + styles.leftContainer}
        style={{ '--left-width': leftWidth } as React.CSSProperties}
      >
        {leftColumn?.blockType === 'richText' ? (
          <div className={styles.richText}>{serializeLexical(leftColumn?.richText)}</div>
        ) : leftColumn?.blockType === 'twoColumnImageBlock' ? (
          <div
            className={styles.imageContainer}
            style={{ '--img-mobile-width': imgWidthOnMobile, width: '100%' } as React.CSSProperties}
          >
            <ImageBlock
              image={leftColumn.image}
              blockType={'imageBlock'}
              sizes={`${LargeImageQuery} 50vw, 100vw`}
            />
          </div>
        ) : null}
      </div>
      {/* ts-ignore */}
      <div
        className={`two-column-right-${id} ` + styles.rightContainer}
        style={{ '--right-width': rightWidth } as React.CSSProperties}
      >
        {rightColumn?.blockType === 'richText' ? (
          <div className={styles.richText}>{serializeLexical(rightColumn?.richText)}</div>
        ) : rightColumn?.blockType === 'twoColumnImageBlock' ? (
          <div
            className={styles.imageContainer}
            style={
              {
                '--img-mobile-width': imgWidthOnMobile,
                width: '100%',
              } as React.CSSProperties
            }
          >
            <ImageBlock
              image={rightColumn.image}
              blockType={'imageBlock'}
              sizes={`${LargeImageQuery} 50vw, 100vw`}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TwoColumn;
