'use client';

import React, { JSX, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import { debounce } from 'next/dist/server/utils';
import { MEDIUM_VIEWPORT_MIN } from '@/src/styles/responsive-breakpoints';
import {
  CarosuelDot,
  SliderContainer,
  SliderDotsContainer,
  SliderItemContainer,
  SliderScrollContainer,
} from './ui.style';

gsap.registerPlugin(ScrollTrigger);

interface SliderCarouselProps {
  items: JSX.Element[];
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  currentIndex: number;
  itemWidth?: number;
}

const SliderCarousel: React.FC<SliderCarouselProps> = (props) => {
  const { items, itemWidth, currentIndex, setCurrentIndex } = props;

  useEffect(() => {
    const scrollContainer = document.querySelector('.scrolling-wrapper');
    const onScroll = debounce(() => {
      if (scrollContainer) {
        if (MEDIUM_VIEWPORT_MIN > window.innerWidth) {
          setCurrentIndex(
            Math.floor(
              (scrollContainer?.scrollLeft + window.innerWidth / 2) /
                (itemWidth ? itemWidth + 32 : window.innerWidth),
            ),
          );
        } else {
          setCurrentIndex(
            Math.ceil(scrollContainer?.scrollLeft / (itemWidth ? itemWidth + 32 : window.innerWidth)),
          );
        }
      }
    }, 30);

    scrollContainer?.addEventListener('scroll', onScroll);
    return () => {
      scrollContainer?.removeEventListener('scroll', onScroll);
    };
  }, [itemWidth, setCurrentIndex]);

  useGSAP(() => {
    const sections = gsap.utils.toArray('.carousel-item');
    gsap.to(sections, {
      ease: 'none',
      scrollTrigger: {
        trigger: '.scrolling-wrapper',
        pin: true,
        scrub: 1,
        snap: 1 / (sections.length - 1),
        // @ts-ignore
        end: () => '+=' + (document.querySelector('.scrolling-wrapper')?.offsetWidth || 0),
      },
    });
  });

  return (
    <SliderContainer>
      <SliderScrollContainer className={'scrolling-wrapper'}>
        {items.map((item, index) => (
          <SliderItemContainer key={index} className={'carousel-item'} itemWidth={itemWidth}>
            {item}
          </SliderItemContainer>
        ))}
      </SliderScrollContainer>
      <SliderDotsContainer>
        {items.map((item, index) => (
          <CarosuelDot $isActive={index === currentIndex} key={index} />
        ))}
      </SliderDotsContainer>
    </SliderContainer>
  );
};

export default SliderCarousel;
