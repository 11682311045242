'use client';

import React from 'react';
import styles from './Masthead.module.scss';
import { anton } from '@/src/fonts';
import { IMasthead } from '@/src/payload-types';
import { useThemeContext } from '@/src/contexts/theme/ThemeContext';
import {
  LARGE_VIEWPORT_MIN,
  LargeImageQuery,
  MEDIUM_VIEWPORT_MIN,
  X_LARGE_VIEWPORT_MIN,
} from '@/src/styles/responsive-breakpoints';
import imageLoader from '@/src/imageLoader';
import Image from 'next/image';

const Masthead: React.FC<IMasthead> = ({ h1, h2, description, image, textWidth, textWidthXl }) => {
  const imageData = image && typeof image !== 'string' && image;
  const [mastheadH2Height, setMastheadH2Height] = React.useState(0);
  const { theme } = useThemeContext();

  React.useEffect(() => {
    const mastheadH2El = document.getElementById('masthead-h2');
    setMastheadH2Height(mastheadH2El?.clientHeight || 0);
  }, []);

  const { imageWidth, imageHeight } = React.useMemo(() => {
    let imageHeight = 0;
    let imageWidth = 0;
    if (imageData && imageData?.url && mastheadH2Height > 0) {
      if (window.innerWidth > LARGE_VIEWPORT_MIN) {
        const topBottomGap = window.innerWidth > X_LARGE_VIEWPORT_MIN ? 144 : 120;
        const headersHeight = mastheadH2Height + topBottomGap * 2 + 48;
        imageHeight = headersHeight - 128;
        imageWidth = imageHeight * ((imageData?.width || 0) / (imageData?.height || 0));
        if (window.innerWidth > X_LARGE_VIEWPORT_MIN && imageWidth > X_LARGE_VIEWPORT_MIN / 2) {
          imageWidth = X_LARGE_VIEWPORT_MIN / 2;
          imageHeight = imageWidth * ((imageData?.height || 0) / (imageData?.width || 0));
        } else if (window.innerWidth > LARGE_VIEWPORT_MIN && imageWidth > LARGE_VIEWPORT_MIN / 2) {
          imageWidth = LARGE_VIEWPORT_MIN / 2;
          imageHeight = imageWidth * ((imageData?.height || 0) / (imageData?.width || 0));
        }
      } else {
        const containerWidth = window.innerWidth > MEDIUM_VIEWPORT_MIN ? 639 : window.innerWidth;
        imageHeight = containerWidth - (window.innerWidth > MEDIUM_VIEWPORT_MIN ? 128 : 64);
        imageHeight =
          window.innerWidth > MEDIUM_VIEWPORT_MIN
            ? imageHeight > 352
              ? 352
              : imageHeight
            : imageHeight
              ? 250
              : imageHeight;
        imageWidth = imageHeight * ((imageData?.width || 0) / (imageData?.height || 0));
      }
    }

    return { imageWidth, imageHeight };
  }, [imageData, mastheadH2Height]);

  return (
    <div className={`${styles.mastheadContainer}`}>
      <div className={styles.mastheadBanner}>
        <div
          className={styles.textContainer}
          style={{ '--text-width': `${textWidth}`, '--text-width-xl': textWidthXl } as React.CSSProperties}
        >
          <h1 className={styles.mastheadH1 + ' ' + (theme === 'dark' ? styles.dark : '')}>{h1}</h1>
          <h2
            id={'masthead-h2'}
            className={
              anton.className + ' ' + (theme === 'dark' ? styles.dark : '') + ' ' + styles.mastheadH2
            }
          >
            {h2}
          </h2>
        </div>
        <div
          className={styles.imageContainer}
          style={
            {
              '--image-width': `calc(100% - ${textWidth})`,
              '--image-width-xl': `calc(100% - ${textWidthXl})`,
            } as React.CSSProperties
          }
        >
          {imageData && imageData?.filename && (
            <Image
              width={imageWidth}
              height={imageHeight}
              loader={imageLoader}
              src={`${imageData.filename}`}
              alt={imageData.alt}
              priority
              sizes={`${LargeImageQuery} 50vw, 100vw`}
            />
          )}
        </div>
      </div>
      <div className={styles.descriptionContainer}>
        {description && (
          <p className={styles.mastheadDescription + ' ' + (theme === 'dark' ? styles.dark : '')}>
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

export default Masthead;
