import { IListItemsBanner } from '@/src/payload-types';
import React from 'react';
import styles from './ListItemsBanner.module.scss';
import { serializeLexical } from '@/src/payload/lexical/serializeLexical';

const ListItemsBanner: React.FC<IListItemsBanner & { style?: React.CSSProperties }> = ({
  backgroundColor,
  richTextList,
  style,
}) => {
  return (
    <div
      className={`${styles.container} bodyRegular banner`}
      style={{ ...style, backgroundColor: backgroundColor ? backgroundColor : '#312F33' }}
    >
      {richTextList && serializeLexical(richTextList, { altTextColor: '#C9AAFF' })}
    </div>
  );
};

export default ListItemsBanner;
