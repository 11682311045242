import imageLoader from '@/src/imageLoader';
import { IImageBlock } from '@/src/payload-types';
import Image from 'next/image';
import React from 'react';

interface Props extends IImageBlock {
  sizes?: string;
}

const ImageBlock: React.FC<Props> = ({ image, caption, sizes }) => {
  return (
    <>
      {image && typeof image !== 'string' && image?.filename && (
        <Image
          width={image?.width || 0}
          height={image?.height || 0}
          src={image?.filename}
          loader={imageLoader}
          alt={image?.alt}
          sizes={sizes}
        />
      )}
    </>
  );
};

export default ImageBlock;
