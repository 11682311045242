import { Blog, BlogCategory } from '@/src/payload-types';
import React from 'react';
import FeaturedInsights from './FeaturedInsights';
import SimpleMasthead from '../shared/blocks/simple-masthead/SimpleMasthead';

const BlogCategoryPage: React.FC<BlogCategory> = ({ masthead, featuredInsights }) => {
  let fi;
  if (featuredInsights && featuredInsights?.length > 0 && typeof featuredInsights[0] !== 'string') {
    fi = featuredInsights as Blog[];
  }
  return (
    <div>
      {masthead && <SimpleMasthead {...masthead} blockType="simpleMasthead" />}
      {fi && <FeaturedInsights featuredInsights={fi} />}
    </div>
  );
};

export default BlogCategoryPage;
