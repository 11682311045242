import React from 'react';
import BlogGalleryItem from './blogs-gallery/BlogGalleryItem';
import styles from './Insights.module.scss';
import { Blog } from '@/src/payload-types';

const FeaturedInsights: React.FC<{ featuredInsights: Blog[] }> = ({ featuredInsights }) => {
  return (
    <div className={styles.insightHomepageContainer}>
      <div className={styles.leftTopContainer}>
        <h3 className={'h3'}>Featured Articles</h3>
        <p>Notable insights from our team.</p>
      </div>
      <div className={styles.rightBottomContainer}>
        {featuredInsights?.map((fi) => {
          if (fi && typeof fi !== 'string') {
            return <BlogGalleryItem blog={fi} key={fi?.id} />;
          }
        })}
      </div>
    </div>
  );
};

export default FeaturedInsights;
