'use client';

import styled from 'styled-components';
import { MediumMediaQuery } from '@/src/styles/responsive-breakpoints';

export const CarosuelDot = styled.div<{ $isActive: boolean }>`
  width: 8px;
  height: 8px;
  background: ${(props) => (props.$isActive ? 'var(--purple-primary)' : 'var(--neutral-500)')};
  border-radius: 50%;
`;

const carosuelItemWidthFullscreen = 'calc(100vw - (2 * var(--side-margin-small)))';
export const CarosuelAnimationContainer = styled.div<{
  currentIndex: number;
  numElements: number;
  itemWidth?: number;
}>`
  width: ${(props) => `calc(${props.itemWidth || carosuelItemWidthFullscreen} * ${props.numElements})`};
  transform: ${(props) =>
    `translateX(calc(-1 * calc(${props.currentIndex} * ${props?.itemWidth ? `${props?.itemWidth || 0}px` : '100vw'})))`};
  display: flex;
  transition: transform 0.5s ease-in-out;
  gap: var(--spacing-section-small);
`;
export const CarosuelItemContainer = styled.div<{ itemWidth?: number }>`
  flex: 0 0 calc(${(props) => props.itemWidth || carosuelItemWidthFullscreen});
`;

export const CarosuelDotsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin: 0 auto;
  margin-top: var(--spacing-section-small);
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  margin-left: -16px;

  ${MediumMediaQuery} {
    margin-left: calc(-0.5 * calc(100vw - 100%));
  }
`;

export const SliderScrollContainer = styled.div`
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: both mandatory;
  overscroll-behavior-x: contain;
  display: flex;
  gap: var(--spacing-section-small);
  height: fit-content !important;
  max-height: fit-content !important;

  .carousel-item:first-child {
    margin-left: var(--spacing-section-small) !important;
  }

  .carousel-item:last-child {
    margin-right: var(--spacing-section-small) !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SliderItemContainer = styled.div<{ itemWidth?: number }>`
  flex: 0 0 calc(${(props) => props.itemWidth || carosuelItemWidthFullscreen});
  scroll-snap-align: center center;
  scroll-snap-stop: normal;
  padding: 32px 0;
`;

export const SliderDotsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  gap: 12px;
  margin: 0 auto;
`;
