import { ISingleColumn } from '@/src/payload-types';
import { serializeLexical } from '@/src/payload/lexical/serializeLexical';
import React from 'react';
import styles from './SingleColumn.module.scss';

const SingleColumn: React.FC<ISingleColumn> = ({ richTextContent, contentAlignment }) => {
  return (
    <div
      className={styles.container}
      style={{
        justifyContent: {
          left: 'flex-start',
          center: 'center',
          right: 'flex-end',
        }[contentAlignment || 'left'],
      }}
    >
      <div className={styles.content + ' bodyRegular'}>
        {richTextContent && serializeLexical(richTextContent)}
      </div>
    </div>
  );
};

export default SingleColumn;
